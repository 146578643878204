export const constants = {
  PROJECT_ISSUE_STATUS: [
    {
      statusId: 1,
      statusName: 'Unsolved'
    },
    {
      statusId: 2,
      statusName: 'Solved'
    }
  ],
  TASK_STATUS_LIST: [
    {
      statusId: null,
      statusName: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'الكل' : 'All',
      class: '',
      isOverDue: false
    },
    {
      statusId: 6,
      statusName: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'لم يبدأ' : 'Not Started',
      class: 'label-violet',
      isOverDue: false
    },
    {
      statusId: 0,
      statusName: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'قيد الإنجاز' : 'Working On',
      statusNameAr: '',
      class: 'label-yellow',
      isOverDue: false
    },
    // {
    //      statusId: 0,
    //     // statusId: 3,
    //     statusName: localStorage.getItem("language") && localStorage.getItem("language") == "ar" ? 'قيد الإنجاز(تجاوز الإستحقاق)' : 'In progress overdue',
    //     class: 'label-dark-danger',
    //     isOverDue: true
    // },
    {
      statusId: 1,
      statusName: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'تم تقديم الطلب' : 'Submitted',
      class: 'label-blue',
      isOverDue: false
    },
    {
      statusId: 7,
      statusName: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'تم تقديم الطلب' : 'On Hold',
      class: 'label-orange',
      isOverDue: false
    },
    // {
    //     statusId: 1,
    //     // statusId: 4,
    //     statusName: localStorage.getItem("language") && localStorage.getItem("language") == "ar" ? 'تم تقديم الطلب (تجاوز الإستحقاق)' : 'Requested overdue',
    //     class: 'label-danger',
    //     isOverDue: true
    // },
    {
      statusId: 2,
      statusName: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'منجز' : 'Completed',
      class: 'label-green',
      isOverDue: false
    }
  ],
  TASK_PRIORITY_LIST: [
    {
      value: 4,
      name: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'أولوية عاجلة جدا' : 'Very Urgent',
      class: 'task-priority-5'
    },
    {
      value: 3,
      name: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'أولوية عاجلة' : 'Urgent',
      class: 'task-priority-4'
    },
    {
      value: 2,
      name: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'هام للغايه' : 'High',
      class: 'task-priority-3'
    },
    {
      value: 1,
      name: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'أولوية متوسطة' : 'Medium',
      class: 'task-priority-2'
    },
    {
      value: 0,
      name: localStorage.getItem("language")
        && localStorage.getItem("language") == "ar" ? 'أولوية منخفضة' : 'Low',
      class: 'task-priority-1'
    },
  ],
  TASK_ACTIVITY_DURATION_LIST: [
    {
      value: 30,
      label: 'Less than 5 hours'
    },
    {
      value: 60,
      label: '1 day'
    },
    {
      value: 90,
      label: '2 - 5 days'
    },
    {
      value: 120,
      label: 'More than 5 days'
    }
  ],
  GENERAL_PROJECT_ID: 1,
  EMPLOYEE_TYPE_EXTERNAL: 4,
  EMPLOYEE_TYPE_INTERNAL: 5,
  EMPLOYEE_TYPE_ALL: 6,
  EMPLOYEE_TYPE_MYTEAM: 7,
  DEVICE_WEB: 2,
  FIREBASE_CONNECTION_STATUS_ONLINE: "Online",
  FIREBASE_CONNECTION_STATUS_OFFLINE: "Offline",
  CONVO_TYPE_GROUP: 'group',
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCdIPv5M0mUooxPbM5v5YiCARI2EIlPb7E",
    authDomain: "tairra-7f828.firebaseapp.com",
    databaseURL: "https://tairra-7f828-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "tairra-7f828",
    storageBucket: "tairra-7f828.appspot.com",
    messagingSenderId: "841623012349",
    appId: "1:841623012349:web:b4842a30dcc9ac26f5cf94",
    measurementId: "G-BL6WJX9K11"

    //  ************ Bahrain ***********  
    // apiKey: "AIzaSyD9XbpkZNl6H62gCfgJUQs7dfQiyYY9dfA",
    // authDomain: "cybasebahrain-a6c64.firebaseapp.com",
    // databaseURL: "",
    // projectId: "cybasebahrain-a6c64",
    // storageBucket: "cybasebahrain-a6c64.appspot.com",
    // messagingSenderId: "840509867067",
    // appId: "1:840509867067:web:f5722506cb22d7ccbc881e"

    // ************* Firebase US ************
    //    apiKey: "AIzaSyB9T_nT-VxGf5i3wYcQJjnOdUY6_zDYchE",
    //    authDomain: "cybaseusae.firebaseapp.com",
    //    databaseURL: "",
    //    projectId: "cybaseusae",
    //    storageBucket: "cybaseusae.appspot.com",
    //    messagingSenderId: "941018484732",
    //    appId: "1:941018484732:web:5eb1e92323a054f115ec79"
  },
  FIREBASE_NEW_CHAT_LIST_NODE: "newChatList",
  FIREBASE_NEW_MESSAGES_NODE: "newMessages",
  FIREBASE_PROJECT_MESSAGES_NODE: "project_",
  FIREBASE_UNREAD_MESSAGES_NODE: "UnreadMessages",
  FIREBASE_PRIVATE_CHAT_UNREADCOUNT_NODE: "UnreadCounts",
  FIREBASE_ISSUE_MESSAGES_NODE: "issue_",
  FIREBASE_TASK_MESSAGES_NODE: "task_",
  FIRESTORE_PROJECT_GROUP_NODE: "_projectGroup",
  FIRESTORE_GROUP_MESSAGES: "messages",
  FIREBASE_TOKEN_NODE: "Tokens",
  GENERAL_TASKS: "General Task",
  GENERAL_TASK_VALUE: 1,
  FIREBASE_USER_NODE: "Users",
  ACTION_TASK_VIEW_MORE: "VIEW_MORE",
  APPLICATION_MODE_GOVT: "cybasegov",
  APPLICATION_MODE_PRIVATE: "digitus"
}
