import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import { AuthService } from "@app/modules/auth/_services";
import { environment } from "@environment/environment";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import "hammerjs";
import { SplashScreenModule } from "@app/_metronic/partials/layout/splash-screen/splash-screen.module";
import { JwtInterceptor } from "@shared/jwt.interceptor";
import {
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
} from "@app/_metronic/partials/layout";
import { ToastrModule } from "ngx-toastr";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { LoaderComponent } from "@shared/components/loader/loader.component";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { CommonService } from "@shared/services/common.service";
import { PipelineService } from "@shared/services/pipeline/pipeline.service";
import { TaskService } from "@pages/tasks/service/task.service";
import { TimesheetService } from "@pages/timesheet/service/timesheet.service";
import { ClientService } from "@pages/client/client.service";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MeetingService } from "@pages/meeting/service/meeting.service";
import { CookieService } from "ngx-cookie-service";
import { UserService } from "@shared/services/user.service";

/* Firebase services */
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularMaterialModule } from "@shared/modules/angular-material.module";
import {MatSliderModule} from '@angular/material/slider';



const firebaseConfig = environment.firebase;

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      let user = authService.getDecodedAccessToken();
      if (user) {
        firebaseConfig.databaseURL = user?.firebae_instance
          ? user?.firebae_instance
          : "";
      }
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    MatProgressSpinnerModule,
    AngularMaterialModule,
    // StoreModule.forRoot(reducers, { metaReducers }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      includeTitleDuplicates: true,
    }),
    MatSnackBarModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    NgxMaterialTimepickerModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    MatSliderModule
  ],
  providers: [
    LayoutConfigService,
    AuthService,
    CommonService,
    PipelineService,
    TaskService,
    TimesheetService,
    ClientService,
    MeetingService,
    UserService,
    KtDialogService,
    LayoutRefService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
